// @import '../../assets/css/variable/variable';;

.button {
  align-items: flex-end;
  border-radius: 8px;
}
.containerbutton {
  display: flex;
  flex-direction: row-reverse;
  margin: 10px;
}
.button:hover {
  background-color: #d7d7d7;
}
.rolevsadminbtn {
  text-transform: none !important;
}
