.crcard {
  border-radius: 20px !important;
  border: 1px solid rgba(153, 67, 236, 0.07);
  background: rgba(153, 67, 236, 0.15);
}
.crcard .card-body {
  padding: 12px !important;
}
.crcard ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.crcard ul li {
  margin-bottom: 1rem;
}
.crcard ul li small {
  color: #bd68a5;
  text-transform: uppercase;
}
/* .crcard ul li h6{color: black;} */
.crcard img {
  border-radius: 10px;
}
.topper-chart {
  display: flex;
  align-items: baseline;

  padding: 0;
  margin: 0;
  width: 100%;
}
.topper-chart li {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  gap: 1rem;
  align-self: self-end;
}
.topper-chart li .bar {
  display: flex;
  color: white;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: 3rem;
  font-weight: 600;
}
.topper-chart li:nth-child(1) .bar {
  background: linear-gradient(180deg, #9d54fe 0%, #d8bcfe 100%);
  height: 87px;
}
.topper-chart li:nth-child(2) .bar {
  background: linear-gradient(180deg, #ff7b20 0%, #fecdab 100%);
  height: 100px;
}
.topper-chart li:nth-child(3) .bar {
  background: linear-gradient(180deg, #42aa2f 0%, #c2f1ba 100%);
  height: 71px;
}
.topstudent {
  margin-top: 5%;
}
