[data-bs-theme='semi-dark'] {
  --bs-semi-body-color: #dee2e6;
  --bs-semi-body-color-rgb: 222, 226, 230;
  --bs-semi-body-bg: #212529;
  --bs-semi-body-bg-2: #181c1f;
  --bs-semi-border-color: #495057;
  --bs-semi-border-color-translucent: rgba(255, 255, 255, 0.15);
}

[data-bs-theme='semi-dark'] body {
  @mixin sidelinks {
    color: #dee2e6;

    &:hover,
    &:focus {
      color: #ffffff;
      background-color: #383c40;
    }
  }

  .top-header {
    .navbar {
      border-bottom: 1px solid #dee2e6;
      box-shadow: none;
      background-color: #eff1f3;

      .btn-toggle {
        a {
          &:hover,
          &:focus {
            color: #383c40;
            background-color: #f9f9f9;
          }
        }
      }
      .nav-right-links {
        .nav-link {
          &:hover,
          &:focus {
            color: #383c40;
            background-color: #f9f9f9;
          }
        }
      }
    }
  }

  .sidebar-wrapper {
    background-color: var(--bs-semi-body-bg);
    border-right: 1px solid var(--bs-semi-border-color);

    .sidebar-header {
      background-color: var(--bs-semi-body-bg);
      border-right: 1px solid var(--bs-semi-border-color);
      .sidebar-close {
        @include sidelinks();
      }
      .logo-name {
        h5 {
          color: var(--bs-semi-body-color);
        }
      }
    }

    /* Metis Menu */
    $color_1: #a7acb1;
    $color_2: #ffffff;
    $color_3: #b0afaf;
    $background-color_1: rgba(255, 255, 255, 0.05);
    $border-color_1: initial;

    .sidebar-nav {
      background-color: var(--bs-semi-body-bg);

      .metismenu {
        background: 0 0;

        ul {
          background-color: var(--bs-semi-body-bg);
        }

        a {
          color: $color_1;

          &:active {
            color: $color_2;
            background-color: $background-color_1;
          }

          &:focus {
            color: $color_2;
            background-color: $background-color_1;
          }

          &:hover {
            color: $color_2;
            background-color: $background-color_1;
          }
        }

        .mm-active {
          > a {
            color: $color_2;
            background-color: $background-color_1;
          }
        }
      }
    }

    .menu-label {
      color: $color_3;
    }

    .metismenu {
      .has-arrow {
        &:after {
          border-color: $border-color_1;
        }
      }
    }

    .sidebar-bottom {
      background-color: var(--bs-semi-body-bg);
      border-top: 1px solid var(--bs-semi-border-color);
      border-right: 1px solid var(--bs-semi-border-color);

      .footer-icon {
        @include sidelinks();
      }

      .dropdown-menu {
        background-color: var(--bs-semi-body-bg-2);
        border: 1px solid var(--bs-semi-border-color-translucent);

        .dropdown-item {
          color: var(--bs-semi-body-color);
          &:hover {
            background-color: var(--bs-semi-body-bg);
          }
        }
      }
    }
  }
}
