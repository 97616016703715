/* ChatStyles.css */
.message-wrapper {
  display: flex;
}

.message-bubble {
  border-radius: 20px;
  padding: 10px;
  margin: 5px;
  max-width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Added shadow */
  transition: transform 0.2s ease-in-out; /* Smooth transition */
}

.message-bubble.left {
  align-self: flex-start;
  background-color: #007bff;
  color: #fff;
}

.message-bubble.right {
  align-self: flex-end;
  background-color: #6c757d;
  color: #fff;
  position: relative;
}
/* .message-bubble::after {
    
 
  border-bottom: 25px solid var(--chatcolor) !important;
} */
.message-bubble.right::after {
  content: '';
  position: absolute;
  right: -15px;
  top: 63%;
  /* transform: translateY(-50%);  */
  transform: translate(-50%, -50%);
  border-width: 10px;
  border-style: solid;
  border-color: transparent;
  border-bottom: 25px solid var(--chatcolor) !important;
}

.message-bubble:hover {
  transform: scale(1.05); /* Slight zoom-in effect */
}

.react-tel-input .country-list {
  bottom: 100%;
  top: auto;
}

.dropdown-container {
  position: relative;
}

.dropdown-wrapper {
  top: auto;
  color: black;
}

.dropdown-wrapper .react-select__menu {
  position: absolute;
  bottom: 100%;
  z-index: 9999; /* Ensure dropdown is on top */
}
.css-z3c6am-MuiFormControl-root-MuiTextField-root {
  width: 100% !important;
}
.react-tel-input .form-control {
  width: 100% !important;
}

.chatinput-body {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  margin-top: 10px;
}
.chat_search_btn {
  border: none;
  background-color: transparent;
}
