// @import '../../assets/css/variable/variable';;

.button {
  align-items: flex-end;
  border-radius: 8px;
}
.containerbutton {
  display: flex;
  flex-direction: row-reverse;
  margin: 10px;
}
.button:hover {
  background-color: #d7d7d7;
}

.floating-label-container {
  position: relative;
  margin-top: 20px;
}

.floating-label {
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
  background-color: var(--inputbackcolor) !important;
  color: var(--inputlablecolor) !important ;
  padding: 0 5px;
  transition: 0.2s;
  pointer-events: none;
}

.floating-label.focused {
  // top: -1px;
  top: -12px;
  font-size: 14px;
  color: var(--inputlablecolor) !important ;
  z-index: 3;
}
.floating-label.focusedempty {
  top: 30px;
  font-size: 18px;
  color: var(--inputlablecolor) !important ;
  z-index: 3;
  left: 20px;
}

.form-control:focus + .floating-label,
.form-control:not(:placeholder-shown) + .floating-label {
  top: -10px;
  font-size: 12px;
  color: var(--inputlablecolor) !important ;
}
.custom-dropdown {
  // background-color: var(--inputbackcolor) !important;
  color: var(--inputlablecolor) !important ;
}

.custom-dropdown option {
  background-color: var(--inputbackcolor) !important;
  color: var(--inputlablecolor) !important;
}
