/* Responsive */
@media screen and (max-width: 1280px) {
  .email-header {
    height: auto;
  }

  .email-content {
    padding: 100px 0 0 0;
  }
}

@media screen and (max-width: 1024px) {
  .email-header {
    border-top-left-radius: 0.25rem;
  }

  .email-sidebar {
    left: -280px;
  }

  .email-content {
    margin-left: 0;
  }

  .email-header {
    left: 0;
  }

  .email-toggled .email-sidebar {
    left: 0;
  }

  .email-toggled .overlay {
    position: absolute;

    top: 0;

    right: 0;

    bottom: 0;

    left: 250px;

    background: #000;

    opacity: 0.5;

    z-index: 9;

    display: block;

    cursor: move;

    transition: all 0.3s ease-out;
  }

  .chat-header {
    border-top-left-radius: 0.25rem;
  }

  .chat-footer {
    border-bottom-left-radius: 0.25rem;
  }

  .chat-sidebar {
    left: -370px;
  }

  .chat-content {
    margin-left: 0;
  }

  .chat-header {
    left: 0;
  }

  .chat-footer {
    left: 0;
  }

  .chat-toggled .chat-sidebar {
    left: 0;
  }

  .chat-toggled .overlay {
    position: absolute;

    top: 0;

    right: 0;

    bottom: 0;

    left: 340px;

    background: #000;

    opacity: 0.5;

    z-index: 11;

    display: block;

    cursor: move;

    transition: all 0.3s ease-out;
  }
}

/* Toogle in responsive */
@media only screen and (max-width: 1199px) {
  .table-responsive {
    white-space: nowrap;
  }
  .sidebar-wrapper {
    left: -260px !important;
    .sidebar-header {
      .sidebar-close {
        display: flex !important;
      }
    }
  }
  .top-header {
    .navbar {
      left: 0 !important;
    }
  }
  .page-content {
    margin-left: 0;
  }
  .toggled {
    .sidebar-wrapper {
      width: 260px;
      left: 0px !important;
      .sidebar-header {
        width: 260px;
      }
      .sidebar-bottom {
        width: 260px;
      }
    }
    .logo-name {
      display: block;
    }
    .top-header {
      .navbar {
        left: 0px !important;
      }
    }
    .page-footer {
      left: 0px !important;
    }
    .main-wrapper {
      margin-left: 0 !important;
    }
    .overlay {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: #000;
      opacity: 0.5;
      z-index: 11;
      display: block;
      cursor: move;
      transition: all 0.23s ease-out;
    }
  }
}

@media only screen and (max-width: 1199px) {
  .top-header {
    .navbar {
      left: 0 !important;
      .dropdown {
        position: static;
        .dropdown-menu {
          width: 100%;
          &::after {
            display: none;
          }
        }
      }
      .mega-menu-widgets {
        padding: 1rem;
        position: relative;
        height: 480px;
        overflow-y: auto;
        overflow-x: hidden;
      }
    }
  }

  .main-wrapper {
    margin-left: 0 !important;
  }

  /*page footer*/

  .page-footer {
    left: 0px !important;
    text-align: center;
    padding: 0.5rem;
    height: auto !important;
    bottom: 0 !important;
    font-size: 11px;
    a {
      font-size: 11px;
    }
  }

  /*mini sidebar*/

  .mini-sidebar {
    left: -70px;
  }
}

@media only screen and (max-width: 992px) {
  .search-popup {
    position: fixed !important;
    top: 0 !important;
    z-index: 10;
  }
  .freechatbox {
    .profilechatinner {
      height: 100vh;
      width: 100% !important;
      border-radius: 0 !important;
      display: flex;
      flex-direction: column;
      max-width: 100% !important;
      margin: 0 !important;

      .afterheader {
        .chat-box {
          height: 84vh !important;
          overflow: auto;
          scrollbar-width: initial !important;
        }
        .chatinput-body {
          position: fixed;
          bottom: 0rem;
          left: 1rem;
          right: 1rem;
          width: auto;
        }
      }
    }
    .copyright {
      display: none;
    }
  }
}
