// // @import '../../assets/css/variable/variable';;

// .chat_section{
//     .searchbar_wrap {
//         display: flex;
//         align-items: center;
//         justify-content: space-between;
//         margin: 0 0 15px;
//         .search_bar {
//             width: 100%;
//         }
//         .add_chat {
//             padding: 0;
//             margin: 0 0 0 15px;
//             width: 52px;
//             min-width: 52px;
//             height: 52px;
//             background-color: $white_color;
//             border-color: rgba(14, 59, 89, 0.2);
//             border-radius: 0.375rem;
//         }
//     }
//     .create_grp {
//         display: flex;
//         justify-content: flex-end;
//         margin: 0 0 15px;
//     }
//     .chat_wrapper
//     {
//         .soundimg
//         {
//             margin-right: 7px;
//         }
//     }
// }
// .BG_chat {
//     // background-color: rgb(164, 248, 248) !important;
//     background-color: #d4f1ff !important;

// }
// .BG_chat::after {

//     // border-bottom: 25px solid  rgb(164, 248, 248) !important;
//     border-bottom: 25px solid #d4f1ff !important;
// }

// .BG_chatA {
//     // background-color:var(--buttonbgcolor) !important;
//     background-color:var(--chatcolor) !important;
//     color: var(--chattextcolor) !important;

// }
// .BG_chatA::after {

//     // border-bottom: 25px solid rgb(142, 240, 215) !important;
//     border-bottom: 25px solid var(--chatcolor) !important;
// }
// .hr_chat{
//     margin: 0 !important;
// }
// .chathedding{
//     background-color: var(--chatsubtitlecolor) !important;
//     display: flex;
//     align-items: center !important;
//     justify-content: center !important;
// }
// .chat_head{
// font-size: medium !important;
//     font-family: sans-serif;
//     font-weight: 400 !important;
// }
// .chat_view{
//     padding: 20px !important;
//     background-color: var(--chatviewbodycolor);
// }
// .mainsearch{
//     color: var(--chatcolor);
//     top:0%
// }
// .search-bar .search-form button {
//     border: 0;
//     padding: 0;
//     margin-left: -24px;
//     background: none;
//     margin-top: 5px;
// }
// .search-bar{
//     background-color: var(--chatviewbodycolor) !important;
//     // margin: none !important;
// }
// // .search-form input:-webkit-autofill {
// //     -webkit-box-shadow:  var(--chatviewbodycolor) inset !important; /* Set the background color you want */
// //     -webkit-text-fill-color: green !important;              /* Set the text color you want */
// //   }

// //   .search-form input:-webkit-autofill:hover,
// //   .search-form input:-webkit-autofill:focus,
// //   .search-form input:-webkit-autofill:active {
// //     -webkit-box-shadow: 0 0 0 1000px white inset !important;
// //     -webkit-text-fill-color: green !important;
// //   }

// // feedback css start
// .feedback-view{
//     padding: 20px !important;
//     background-color: var(--chatviewbodycolor);
//     .message-bubble{
//         background-color: var(--chatbodyinnercolor);
//         font-size: 20px;
//         &:hover{
//             transform: unset;
//         }
//         +.row{
//             padding: 15px 20px;
//         }
//     }

// }
.input-container {
  position: relative;
  width: 100%; // The container is 100% width
  max-width: 400px; // You can limit the max-width if needed
}

.custom-input {
  width: 100%;
  padding-right: 50px; /* Space for the button */
  padding-right: 5% !important; /* Creates 5% padding on the left */
  box-sizing: border-box;
  font-size: 16px;
  white-space: nowrap; /* Prevents text from wrapping */
  overflow-x: auto; /* Allows scrolling when the text overflows */
}

.custom-input:focus {
  overflow-x: scroll; /* Enables horizontal scrolling when focused */
}

.overlap-button {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  background: #007bff;
  color: white;
  border: none;
  padding: 0 15px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 0 5px 5px 0;
}

.ps .ps__rail-x {
  display: none !important;
}
