[data-bs-theme='dark'] body {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg-2) !important;
  $primary: #9943ec;
  $headingColor: #d5d5d5;
  $headerbg: #212529;
  $date_text: #d5d5d5 !important;
  $mui_date_bg: #32363b !important;
  .chat-panel .main-chat-panel .inner-panel .chat-result ul li .chat-card {
    background-color: #212529 !important;
  }
  .feedback .feedback-questions .question {
    background: rgb(75 75 75 / 48%) !important;
  }
  .MuiMenu-list {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .seprator span {
    background-color: $headerbg;
  }
  .seprator::before {
    background-color: rgba($headingColor, 0.3);
  }
  .seprator::after {
    background-color: rgba($headingColor, 0.3);
  }
  .css-9g6qmn-MuiFormControl-root-MuiTextField-root input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px #2f3336 inset !important;
    -webkit-text-fill-color: $headingColor !important;
  }
  .without-login {
    background-color: transparent !important;
  }
  .access-card {
    background-color: $headerbg !important;

    svg {
      path {
        fill: #939aa0;
      }
    }
  }
  .freechatbox {
    .profilechatinner {
      background-color: $headerbg !important;
      .MuiIconButton-root {
        svg {
          fill: rgba($headingColor, 0.6);
        }
      }
      .chatinput-body {
        input,
        select,
        .css-13cymwt-control {
          background-color: rgb(75 75 75 / 48%) !important;
        }
      }
      .message-bubble.left {
        background-color: rgb(75 75 75 / 48%) !important;
        color: $headingColor !important;
      }
      .message-bubble.right {
        background-color: $primary !important;
      }
    }
  }
  .css-1tzjnoi-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root,
  .MuiInputBase-colorPrimary {
    background-color: #32363b !important;
    border-color: #32363b !important;
  }
  .MuiTextField-root {
    background-color: transparent !important;
  }

  .MuiSelect-outlined,
  .MuiInputBase-input,
  .MuiInputBase-input {
    color: $headingColor !important;
  }
  .Mui-disabled {
    -webkit-text-fill-color: $date_text;
  }
  .MuiIconButton-root {
    color: $date_text;
  }
  .MuiPickersPopper-paper {
    background-color: $mui_date_bg;
  }
  .MuiPickersCalendarHeader-root {
    color: $date_text;
  }
  .MuiDayCalendar-weekDayLabel {
    color: $date_text;
  }
  .MuiPickersDay-root {
    color: $date_text;
  }
  .MuiSelect-icon {
    color: $date_text;
  }
  .MuiPickersYear-yearButton {
    color: $date_text;
  }
  .addcomingsoon {
    &::after {
      background-color: rgba(75, 75, 75, 0.8);
    }
  }
  .MuiFormLabel-colorPrimary {
    color: $headingColor !important;
  }
  .wizard-content .form-control,
  .wizard-content .form-select,
  .btn-dark,
  .wizard-content .progress-bar,
  .chat-top-header-menu a {
    background-color: #32363b !important;
    border-color: #32363b !important;
  }
  .btn-outline-dark {
    border-color: #32363b;
    color: white;
  }
  .chat-wrapper {
    background-color: #212529 !important;
  }
  .form-control:focus {
    box-shadow: none !important;
    border-color: #495057 !important;
  }
  .accordion-item:not(:first-of-type) {
    border-top: 1px solid rgba(73, 80, 87, 1) !important;
  }
  l .chat-wrapper,
  .chat-content {
    background-color: #212529 !important;
  }

  input::placeholder {
    color: #fff !important;
  }
  .bg-primary-20 {
    background-color: #32363b !important;
  }
  .show-seprate {
    border: 1px solid #878d96 !important;
  }
  .link-underline,
  .text-dark {
    color: #dee2e6 !important;
    &::after {
      background-color: #939aa0 !important;
    }
  }
  .btn-outline-light {
    background-color: #212529 !important;
    color: #d5d5d5 !important;
  }
  .bg-light,
  .chat-header,
  .chat-footer {
    background-color: #32363b !important;
  }
  .chat-suggestion {
    li {
      background-color: $headerbg !important;
    }
  }

  .card-title {
    color: #dee2e6;
  }
  .chat-panel .main-chat-panel .mobile-chat-header {
    background-color: #32363b !important;
    i {
      color: #dee2e6 !important;
    }
  }
  .chat-result {
    background-color: #000 !important;
  }
  .chat-content-leftside .chat-left-msg {
    background-image: linear-gradient(
      310deg,
      #32363b 0%,
      #32363b 100%
    ) !important;
    color: white;
  }
  /* aligns */

  @mixin sidelinks {
    color: #dee2e6;

    &:hover,
    &:focus {
      color: #ffffff;
      background-color: #383c40;
    }
  }

  @media (min-width: 1024px) {
    .left-side-history {
      border-right: 1px solid rgba($stroke-100, 0.1) !important;
    }
  }

  /*header*/

  .top-header {
    .navbar {
      background-color: $headerbg;

      .search-bar {
        .search-control {
          &:focus {
            border: 1px solid var(--bs-border-color);
          }
        }

        .search-popup {
          background-color: var(--bs-body-bg);

          .card {
            border: 1px solid var(--bs-border-color-translucent);
          }

          .search-title {
            color: #939aa0;
          }

          .kewords {
            color: var(--bs-body-color);
            background-color: var(--bs-body-bg-2);

            &:hover {
              color: #efefef;
              background-color: var(--bs-body-bg-2);
            }
          }

          .search-list-item {
            &:hover {
              color: var(--bs-body-color);
              background-color: var(--bs-body-bg-2);
            }

            .list-icon {
              background-color: var(--bs-body-bg-2);
            }
          }
        }
      }

      .nav-item {
        .mega-menu {
          background-color: var(--bs-body-bg-2);

          .mega-menu-icon {
            background-color: #f8f8f8;
          }

          .card {
            &:hover {
              background-color: var(--bs-body-bg-2);
            }
          }
        }

        .dropdown-apps {
          .app-wrapper {
            background-color: var(--bs-body-bg);

            &:hover {
              background-color: var(--bs-body-bg-2);
            }
          }
        }

        .dropdown-notify {
          .option {
            color: var(--bs-body-color);
            background-color: var(--bs-body-bg);
            border: 0px solid var(--bs-border-color-translucent);

            &:hover {
              background-color: var(--bs-body-bg-2);
            }
          }

          .notify-title {
            color: var(--bs-body-color);
          }

          .notify-desc {
            color: rgba(
              var(--bs-body-color-rgb),
              var(--bs-text-opacity)
            ) !important;
          }

          .notify-time {
            color: #939aa0;
          }

          .user-wrapper {
            background-color: #efefef;
          }

          .notify-close {
            background-color: var(--bs-body-bg-2);
          }
        }
      }

      .dropdown-menu {
        border: 1px solid var(--bs-border-color-translucent);
      }

      .dropdown-menu::after {
        background: var(--bs-body-bg);
        border-top: 1px solid var(--bs-border-color);
        border-left: 1px solid var(--bs-border-color);
      }

      .btn-toggle {
        a {
          @include sidelinks();
        }
      }

      .nav-right-links {
        .nav-link {
          @include sidelinks();
        }
      }
    }
  }

  /* sidebar */

  .sidebar-wrapper {
    background-color: var(--bs-body-bg);
    border-right: 1px solid var(--bs-border-color);

    .sidebar-header {
      background-color: var(--bs-body-bg);
      border-right: 1px solid var(--bs-border-color);
      .sidebar-close {
        @include sidelinks();
      }
    }

    .sidebar-nav {
      background-color: var(--bs-body-bg);
    }

    .sidebar-bottom {
      background-color: var(--bs-body-bg);
      border-top: 1px solid var(--bs-border-color);
      border-right: 1px solid var(--bs-border-color);

      .footer-icon {
        @include sidelinks();
      }

      .dropdown-menu {
        background-color: var(--bs-body-bg-2);
        border: 1px solid var(--bs-border-color-translucent);
      }
    }
  }

  .chip {
    color: var(--bs-body-color);
    background-color: var(--bs-body-bg-2);
    border: 1px solid var(--bs-border-color);
  }

  /* main content */

  .main-wrapper {
    .main-content {
      .chat-panel {
        .left-side-history {
          .history-list {
            li {
              &::before {
                content: '';
                position: absolute;
                background-image: url('../icons/search-circle-white.svg');
                width: 38px;
                height: 38px;
                background-size: 100%;
                left: 0;
              }
            }
          }
        }
      }
      .breadcrumb-title {
        border-right: 1.5px solid var(--bs-border-color-translucent);
      }

      .sharelink {
        @include sidelinks();
      }

      .options {
        @include sidelinks();
      }

      .vertical-pills {
        .nav-link {
          border-bottom: 1px solid var(--bs-border-color);
          color: var(--bs-body-color);

          &:last-child {
            border-bottom: 0px solid #dee2e6;
          }
        }
      }

      .customer-table {
        table {
          .customer-name {
            color: var(--bs-body-color);
          }
        }
      }

      .product-table {
        table {
          .product-category {
            color: #878d96;
          }

          .product-title {
            color: var(--bs-body-color);
          }

          .product-tags {
            .btn-tags {
              background-color: var(--bs-body-bg-2);
              color: var(--bs-body-color);
            }
          }

          .product-rating {
            background-color: var(--bs-body-bg);
            border: 1px solid var(--bs-border-color);
          }
        }
      }

      .apexcharts-datalabel,
      .apexcharts-datalabel-label,
      .apexcharts-datalabel-value,
      .apexcharts-datalabels,
      .apexcharts-pie-label {
        fill: #fff;
      }
    }
  }

  .separator {
    .line {
      background-color: var(--bs-border-color);
    }
  }

  .auth-cover-left {
    background-color: var(--bs-body-bg);
  }

  .order-delete {
    @include sidelinks();
    cursor: pointer;
  }

  /*page footer*/

  .page-footer {
    background-color: $headerbg;
    border-top: 0px solid var(--bs-border-color);
  }

  /* Metis Menu */
  $color_1: #a7acb1;
  $color_2: #ffffff;
  $color_3: #b0afaf;
  $background-color_1: rgba(255, 255, 255, 0.05);
  $border-color_1: initial;

  .sidebar-nav {
    .metismenu {
      background: 0 0;

      a {
        color: $color_1;

        &:active {
          color: $color_2;
          background-color: $background-color_1;
        }

        &:focus {
          color: $color_2;
          background-color: $background-color_1;
        }

        &:hover {
          color: $color_2;
          background-color: $background-color_1;
        }
      }

      ul {
        background-color: var(--bs-body-bg);
      }

      .mm-active {
        > a {
          color: $color_2;
          background-color: $background-color_1;
        }
      }
    }
  }

  .menu-label {
    color: $color_3;
  }

  .metismenu {
    .has-arrow {
      &:after {
        border-color: $border-color_1;
      }
    }
  }

  /* order offcanvas */

  /* utilities */

  .primaery-menu-close {
    @include sidelinks();
  }

  .theme-icons {
    background-color: var(--bs-body-bg);
  }

  .error {
    color: #fe1010;
  }

  .dash-lable {
    background-color: #f3f3f3;
  }

  form select.error,
  form textarea.error,
  form input.error,
  form input.error:focus,
  form textarea.error:focus,
  form select.error:focus {
    border-color: #fe1010 !important;
  }

  .gmaps,
  .gmaps-panaroma {
    background: var(--bs-body-bg-2);
  }

  .bootstrap-tagsinput {
    background-color: var(--bs-body-bg);
    border-color: var(--bs-border-color);
  }
}
