$primary: #9943ec;
[data-bs-theme='blue-theme'] body {
  --bs-heading-color: #e6ecf0;
  --bs-body-color: #d3d7dc;
  --bs-body-bg: #0f1535;
  --bs-body-bg-2: #181f4a;
  --bs-transparent-bg: rgba(255, 255, 255, 0.1);
  --bs-border-color-translucent: rgba(226, 232, 240, 0.15);
  --bs-border-color: rgba(255, 255, 255, 0.15);
  --sticky-header-bg: rgba(255, 255, 255, 0.45);
  --bs-disabled-bg: #4b5481;
  --bs-tertiary-bg: #181f4a;
  --bs-card-bg: #070c29;

  background-image: url(../../assets/img/body-background-1.webp);
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: transparent;

  color: var(--bs-body-color);
  background-color: var(--bs-body-bg-2);

  $headingColor: #d5d5d5;
  $headerbg: transparent;

  .form-control:hover:not(:disabled):not([readonly])::file-selector-button {
    background-color: var(--bs-tertiary-bg);
  }
  .chat-content-leftside .chat-left-msg {
    background-image: linear-gradient(
      127.09deg,
      rgb(0 8 57 / 94%) 19.41%,
      rgb(2 10 49) 76.65%
    ) !important;
    color: #dee2e6;
  }
  textarea::placeholder {
    color: #fff !important;
    opacity: 0.5;
  }

  input::placeholder {
    color: #fff !important;
  }

  .bg-light {
    background-color: #171e44 !important;
  }

  .bg-none {
    background-image: none !important;
  }
  .card {
    background-image: linear-gradient(
      127.09deg,
      rgba(6, 11, 40, 0.94) 19.41%,
      rgba(10, 14, 35, 0.49) 76.65%
    );
  }
  .question {
    background-color: #070c29 !important;
  }
  .card-title {
    color: #dee2e6;
  }

  /* aligns */

  @mixin sidelinks {
    color: #dee2e6;

    &:hover,
    &:focus {
      color: #ffffff;
      background-color: var(--bs-body-bg-2);
    }
  }

  /*header*/

  .top-header {
    .navbar {
      background-color: $headerbg;
      box-shadow: none;

      .search-bar {
        .search-control {
          &:focus {
            border: 1px solid var(--bs-border-color);
          }
        }
        .search-popup {
          background-color: var(--bs-body-bg);
          .card {
            border: 1px solid var(--bs-border-color-translucent);
          }
          .search-title {
            color: #939aa0;
          }
          .kewords {
            color: var(--bs-body-color);
            background-color: var(--bs-body-bg);
            border: 1px solid;
            border-color: var(--bs-border-color);

            &:hover {
              color: var(--bs-body-color);
              background-color: var(--bs-body-bg-2);
            }
          }
          .search-list-item {
            &:hover {
              color: var(--bs-body-color);
              background-color: var(--bs-body-bg-2);
            }

            .list-icon {
              background-color: var(--bs-body-bg-2);
            }
          }
        }
      }

      .nav-item {
        .mega-menu {
          background-color: var(--bs-body-bg-2);

          .mega-menu-icon {
            background-color: var(--bs-body-bg);
          }

          .card {
            &:hover {
              background-color: var(--bs-body-bg-2);
            }
          }
        }

        .dropdown-apps {
          .app-wrapper {
            background-color: var(--bs-body-bg);

            &:hover {
              background-color: var(--bs-body-bg-2);
            }
          }
        }

        .dropdown-notify {
          .option {
            color: var(--bs-body-color);
            background-color: var(--bs-body-bg);
            border: 0px solid var(--bs-border-color-translucent);

            &:hover {
              background-color: var(--bs-body-bg-2);
            }
          }

          .notify-title {
            color: var(--bs-body-color);
          }

          .notify-desc {
            color: rgba(
              var(--bs-body-color-rgb),
              var(--bs-text-opacity)
            ) !important;
          }

          .notify-time {
            color: #939aa0;
          }

          .user-wrapper {
            background-color: #efefef;
          }

          .notify-close {
            background-color: var(--bs-body-bg-2);
          }
        }
      }

      .dropdown-menu {
        border: 1px solid var(--bs-border-color-translucent);
      }

      .dropdown-menu::after {
        background: var(--bs-body-bg);
        border-top: 1px solid var(--bs-border-color);
        border-left: 1px solid var(--bs-border-color);
      }

      .btn-toggle {
        a {
          @include sidelinks();
        }
      }

      .nav-right-links {
        .nav-link {
          @include sidelinks();
        }
      }
    }
  }

  /* sidebar */

  .sidebar-wrapper {
    background-color: var(--bs-body-bg);
    border-right: 1px solid var(--bs-border-color);

    .sidebar-header {
      background-color: var(--bs-body-bg);
      border-right: 1px solid var(--bs-border-color);

      .sidebar-close {
        @include sidelinks();
      }
    }

    .sidebar-nav {
      background-color: var(--bs-body-bg);
    }

    .sidebar-bottom {
      background-color: var(--bs-body-bg);
      border-top: 1px solid var(--bs-border-color);
      border-right: 1px solid var(--bs-border-color);

      .footer-icon {
        @include sidelinks();
      }

      .dropdown-menu {
        background-color: var(--bs-body-bg-2);
        border: 1px solid var(--bs-border-color-translucent);
      }
    }
  }

  .chip {
    color: var(--bs-body-color);
    background-color: var(--bs-body-bg-2);
    border: 1px solid var(--bs-border-color);
  }

  .dropdown-menu {
    border: 1px solid var(--bs-border-color-translucent);
  }

  .form-select:disabled,
  .form-control:disabled {
    background-color: var(--bs-disabled-bg);
  }

  /* main content */

  .main-wrapper {
    .main-content {
      .sharelink {
        @include sidelinks();
      }

      .options {
        @include sidelinks();
      }

      .vertical-pills {
        .nav-link {
          border-bottom: 1px solid var(--bs-border-color);
          color: var(--bs-body-color);

          &:last-child {
            border-bottom: 0px solid #dee2e6;
          }
        }
      }

      .customer-table {
        table {
          .customer-name {
            color: var(--bs-body-color);
          }
        }
      }

      .product-table {
        table {
          .product-category {
            color: #878d96;
          }

          .product-title {
            color: var(--bs-body-color);
          }

          .product-tags {
            .btn-tags {
              background-color: var(--bs-body-bg-2);
              color: var(--bs-body-color);
            }
          }

          .product-rating {
            background-color: var(--bs-body-bg);
            border: 1px solid var(--bs-border-color);
          }
        }
      }

      .apexcharts-datalabel,
      .apexcharts-datalabel-label,
      .apexcharts-datalabel-value,
      .apexcharts-datalabels,
      .apexcharts-pie-label {
        fill: #fff;
      }
    }
  }

  .separator {
    .line {
      background-color: var(--bs-border-color);
    }
  }

  .auth-cover-right {
    background-color: var(--bs-body-bg);
  }

  .auth-cover-left {
    background-color: var(--bs-body-bg);
  }

  .order-delete {
    @include sidelinks();
    cursor: pointer;
  }

  /*page footer*/

  .page-footer {
    background-color: $headerbg;
    border-top: 1px solid var(--bs-border-color);
  }

  /* Metis Menu */
  $color_1: #a7acb1;
  $color_2: #ffffff;
  $color_3: #b0afaf;
  $background-color_1: rgba(255, 255, 255, 0.05);
  $border-color_1: initial;

  .sidebar-nav {
    .metismenu {
      background: 0 0;

      a {
        color: $color_1;

        &:active {
          color: $color_2;
          background-color: $background-color_1;
        }

        &:focus {
          color: $color_2;
          background-color: $background-color_1;
        }

        &:hover {
          color: $color_2;
          background-color: $background-color_1;
        }
      }

      ul {
        background-color: var(--bs-body-bg);
      }

      .mm-active {
        > a {
          color: $color_2;
          background-color: $background-color_1;
        }
      }
    }
  }

  .menu-label {
    color: $color_3;
  }

  .metismenu {
    .has-arrow {
      &:after {
        border-color: $border-color_1;
      }
    }
  }

  /* order offcanvas */

  /* utilities */

  .primaery-menu-close {
    @include sidelinks();
  }

  .theme-icons {
    background-color: var(--bs-body-bg);
  }

  .error {
    color: #fe1010;
  }

  .dash-lable {
    background-color: #f3f3f3;
  }

  form select.error,
  form textarea.error,
  form input.error,
  form input.error:focus,
  form textarea.error:focus,
  form select.error:focus {
    border-color: #fe1010 !important;
  }

  .gmaps,
  .gmaps-panaroma {
    background: var(--bs-body-bg-2);
  }

  .bootstrap-tagsinput {
    background-color: var(--bs-body-bg);
    border-color: var(--bs-border-color);
  }

  .page-breadcrumb {
    .breadcrumb-item.active {
      color: var(--bs-heading-color);
    }

    .breadcrumb-title {
      color: var(--bs-heading-color);
      font-size: 20px;
      border-right: 1.5px solid;
      border-color: var(--bs-border-color);
    }

    .breadcrumb-item + .breadcrumb-item {
      &::before {
        color: #fff;
      }
    }
  }

  .dropdown-item {
    &:hover {
      background-color: var(--bs-body-bg-2);
    }

    &:focus {
      background-color: var(--bs-body-bg-2);
    }
  }

  .sticky-header {
    box-shadow:
      rgba(255, 255, 255, 0.9) 0rem 0rem 0.0625rem 0.0625rem inset,
      rgba(0, 0, 0, 0.05) 0rem 1.25rem 1.6875rem 0rem;
    backdrop-filter: blur(2.625rem);
    border-bottom: 1px solid var(--bs-border-color);
  }

  .progress {
    background-color: var(--bs-transparent-bg);
  }

  .bs-stepper-circle {
    color: var(--bs-body-color);
    background-color: var(--bs-body-bg);
  }

  .active .bs-stepper-circle {
    color: #ffffff;
    background-color: #007bff;
    background-image: linear-gradient(310deg, #7928ca, #ff0080) !important;
  }

  .bs-stepper.vertical .bs-stepper-header {
    background-color: var(--bs-body-bg);
    border-right: 1px solid var(--bs-border-color-translucent);
  }

  .imageuploadify {
    background-color: var(--bs-body-bg);
  }

  .select2-container--bootstrap-5 {
    .select2-selection {
      background-color: var(--bs-body-bg);
      border-color: var(--bs-border-color-translucent);
    }
  }

  .select2-selection__rendered {
    color: var(--bs-body-color) !important;
  }

  .select2-selection__choice {
    color: var(--bs-body-color) !important;
    border-color: var(--bs-border-color-translucent) !important;
  }

  .select2-dropdown {
    color: #ffffff;
    background-color: #0f1535;
  }

  .select2-search__field {
    color: var(--bs-body-color) !important;
    border-color: var(--bs-border-color-translucent) !important;
    background-color: var(--bs-body-bg);
  }

  .fc-theme-standard .fc-scrollgrid,
  .fc-theme-standard td,
  .fc-theme-standard th {
    border: 1px solid var(--bs-border-color-translucent);
    border: 1px solid var(--fc-border-color, var(--bs-border-color-translucent));
  }

  .fc .fc-non-business {
    background-color: var(--bs-body-bg);
  }

  .btn-light {
    color: var(--bs-body-color);
    background-color: var(--bs-body-bg-2);
    border-color: var(--bs-border-color-translucent);
  }

  .mail-read {
    color: #a2b0c5 !important;
    background-color: var(--bs-body-bg-2) !important;
  }

  /* email box */
  .email-wrapper {
    background-color: var(--bs-body-bg);
  }
  .email-sidebar {
    background-color: var(--bs-body-bg);
    border-right: 1px solid var(--bs-border-color-translucent);
  }

  .email-sidebar-header {
    background-color: var(--bs-body-bg);
    border-bottom: 1px solid var(--bs-border-color-translucent);
  }

  .email-navigation {
    border-bottom: 1px solid var(--bs-border-color-translucent);

    a {
      &.list-group-item {
        color: var(--bs-body-color-rgb);
        background-color: var(--bs-body-bg);

        &:hover {
          background-color: var(--bs-body-bg-2);
        }

        &.active {
          color: #0b5ed7;
          background-color: var(--bs-body-bg-2);
        }
      }
    }
  }

  .email-header {
    background-color: var(--bs-body-bg);
    border-bottom: 1px solid var(--bs-border-color-translucent);
  }

  .email-content {
    background-color: var(--bs-body-bg);
  }

  .email-meeting {
    a {
      &.list-group-item {
        color: var(--bs-body-color-rgb);
        background-color: var(--bs-body-bg);

        &:hover {
          background-color: var(--bs-body-bg-2);
        }
      }
    }
  }

  .email-hangout {
    .chat-user-online {
      &:before {
        box-shadow: 0 0 0 2px #fff;
        background: #16e15e;
      }
    }
  }

  .email-toggle-btn {
    color: var(--bs-body-color);
    background-color: var(--bs-body-bg);
  }

  .email-time {
    color: var(--bs-body-color);
  }

  .email-list {
    div {
      &.email-message {
        background-color: var(--bs-body-bg);
        border-bottom: 1px solid rgb(0 0 0 / 8%);
        color: var(--bs-body-color);

        &:hover {
          transition: all 0.2s ease-out;
          background-color: var(--bs-body-bg-2) !important;
          color: var(--bs-body-color);
        }
      }
    }
  }

  .email-star {
    color: #6c757d;
  }

  .email-read-box {
    position: relative;
    height: 530px;
  }

  .compose-mail-popup {
    width: 42%;
    position: fixed;
    bottom: -30px;
    right: 30px;
    z-index: 15;
    display: none;
  }

  .compose-mail-toggled {
    display: block;
  }

  .compose-mail-title {
    font-size: 16px;
  }

  .compose-mail-close {
    width: 25px;
    height: 25px;
    line-height: 25px;
    text-align: center;
    font-size: 14px;
    border-radius: 2px;
    background-color: rgb(255 255 255 / 0%);

    &:hover {
      background-color: rgb(255 255 255 / 20%);
    }
  }

  /* chat box */

  .chat-wrapper {
    background-color: var(--bs-body-bg);
    box-shadow: 0 0.1rem 0.7rem rgba(0, 0, 0, 0.1);
  }
  .chat-sidebar {
    background-color: var(--bs-body-bg);
    border-right: 1px solid var(--bs-border-color-translucent);
  }
  .chat-sidebar-header {
    background-color: var(--bs-body-bg);
    border-bottom: 1px solid var(--bs-border-color-translucent);
  }
}

.chat-header {
  background-color: var(--bs-body-bg);
  border-bottom: 1px solid var(--bs-border-color-translucent);
}
.chat-footer {
  background-color: var(--bs-body-bg);
  border-top: 1px solid var(--bs-border-color-translucent);
}
.chat-footer-menu {
  a {
    color: var(--bs-body-color);
    background-color: var(--bs-body-bg);
    border: 1px solid var(--bs-border-color-translucent);
  }
}
.chat-tab-menu {
  li {
    a {
      &.nav-link {
        color: var(--bs-body-color);
      }
    }
  }
}
.chat-tab-menu .nav-pills .nav-link.active,
.chat-tab-menu .nav-pills .show > .nav-link {
  color: #008cff;
  background-color: rgb(0 123 255 / 0%);
}
.chat-title {
  color: var(--bs-body-color);
}
.chat-msg {
  color: #a3a7aa;
}
.chat-time {
  color: #cdcfd2;
}
.chart-online {
  color: #16e15e;
}
.chat-top-header-menu {
  a {
    color: var(--bs-body-color);
    background-color: var(--bs-body-bg-2);
    border: 1px solid var(--bs-border-color-translucent);
  }
}
.chat-content-leftside {
  margin-bottom: 1rem;
  .chat-left-msg {
    background-image: linear-gradient(310deg, #fff 0%, #fff 100%) !important;
    color: #333333;
    box-shadow: 0px 12px 24px 0px rgba(11, 6, 16, 0.08);
    border: 1px solid rgba(10, 5, 15, 0.1) !important;
    font-size: 14px;

    span.anstext {
      color: $primary;
      display: flex;
      gap: 0.3rem;
      align-items: center;
      margin-bottom: 0.6rem;
      i {
        font-size: 14px;
      }
    }
    .ansfooter {
      display: flex;
      align-items: center;
      list-style: none;
      padding: 0;
      margin: 0;
      gap: 1rem;
      position: relative;
      li {
        display: flex;
        align-items: center;
        gap: 0.3rem;
        font-size: 12px;
        cursor: pointer;
        i {
          font-size: 14px;
        }
      }

      &::before {
        background: linear-gradient(
          90deg,
          rgba(10, 5, 15, 0) 0%,
          rgba(10, 5, 15, 0.16) 25%,
          rgba(10, 5, 15, 0.16) 75%,
          rgba(10, 5, 15, 0) 100%
        );
        content: '';
        height: 2px;
        width: 100%;
        position: absolute;
        left: 0rem;
        right: 0rem;
        top: -0.7rem;
      }
    }
  }
}
.chat-content-rightside {
  .chat-right-msg {
    background-image: linear-gradient(310deg, #9943ec, #9943ec) !important;
    font-size: 14px;
    margin-bottom: 1rem;
    .anstext {
      display: flex;
      align-items: center;
      gap: 0.3rem;
      margin-bottom: 0.5rem;
      i {
        font-size: 18px;
      }
    }
  }
}
.chat-toggle-btn {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg-2);
  border: 1px solid var(--bs-border-color-translucent);
}

/* file manager */

.fm-menu {
  .list-group {
    a {
      color: var(--bs-body-color);
      i {
        font-size: 23px;
      }
      &:hover {
        background: #008cff;
      }
    }
  }
}
.fm-file-box {
  background-color: var(--bs-body-bg-2);
}
.fm-icon-box {
  background-color: var(--bs-body-bg-2);
}
.user-plus {
  background-color: var(--bs-body-bg-2);
  border: 1px dotted var(--bs-border-color-translucent);
  color: var(--bs-body-color);
}
.user-groups {
  img {
    border: 1px solid var(--bs-border-color-translucent);
  }
}

.table {
  --bs-table-color: var(--bs-body-color);
  --bs-table-bg: var(--bs-table-bg);
  --bs-table-border-color: var(--bs-border-color);
  --bs-table-striped-color: var(--bs-body-color);
  --bs-table-striped-bg: rgba(255, 255, 255, 0.05);
  --bs-table-active-color: var(--bs-body-color);
  --bs-table-active-bg: rgba(255, 255, 255, 0.1);
  --bs-table-hover-color: var(--bs-body-color);
  --bs-table-hover-bg: rgba(255, 255, 255, 0.075);
  border-color: var(--bs-table-border-color);
}

.list-group {
  --bs-list-group-color: var(--bs-body-color);
  --bs-list-group-bg: var(--bs-card-bg);
  --bs-list-group-border-color: var(--bs-border-color);
  --bs-list-group-border-width: var(--bs-border-width);
  --bs-list-group-border-radius: var(--bs-border-radius);
  --bs-list-group-item-padding-x: 1rem;
  --bs-list-group-item-padding-y: 0.5rem;
  --bs-list-group-action-color: var(--bs-secondary-color);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-tertiary-bg);
  --bs-list-group-action-active-color: var(--bs-body-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-bg);
  --bs-list-group-disabled-color: var(--bs-secondary-color);
  --bs-list-group-disabled-bg: var(--bs-body-bg);
  --bs-list-group-active-color: #fff;
  --bs-list-group-active-bg: #0d6efd;
  --bs-list-group-active-border-color: #0d6efd;
  border-radius: var(--bs-list-group-border-radius);
}

.card-lable {
  font-size: 13px;
  padding: 3px 8px;
  border-radius: 4px;
  width: max-content;
}
